import API from "../../service/API"
import { Dashboard, Form } from "../../component";
import React from "react";
function Buttons(attr) {
    return <>
        <button className="btn btn-sm btn-primary fa fa-plus" { ...attr }></button>
    </>
}
export default function (attr) {
    const [form, setForm] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [user, setUser] = React.useState({});
    React.useEffect(() => {
        const api = new API();
        api
            .get("/api/v1/user")
            .then(r => {
                setUsers(r.data);
            })
    }, [form])
    const addUser = function () {
        setForm("adduser");
        setUser({});
    }
    return <>
        {
            {
                null: <Dashboard.Card title="Users" buttons={ <Buttons onClick={ addUser } /> }>
                    <table className="table align-items-center mb-0">
                        <thead>
                            <tr>
                                <th>Sl.No.</th>
                                <th>Username</th>
                                <th>Name</th>
                                <th>E Mail</th>
                                <th>Phone No.</th>
                                <th>Role</th>
                                <th colSpan={ 3 }></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.map((user, index) => <tr key={ user._id }>
                                    <td>{ (index + 1) }</td>
                                    <td>{ user.user }</td>
                                    <td>{ user.name }</td>
                                    <td>{ user.email }</td>
                                    <td>{ user.phone }</td>
                                    <td>{ user.role }</td>
                                    <td>
                                        <button
                                            onClick={
                                                e => {
                                                    setUser(user);
                                                    setForm("deleteuser");
                                                }
                                            }
                                            className="btn btn-sm btn-danger fa fa-times"></button>
                                    </td>
                                    <td>
                                        <button onClick={
                                            e => {
                                                setUser(user);
                                                setForm("updateuser");
                                            }
                                        } className="btn btn-sm btn-secondary fa fa-pencil"></button>
                                    </td>
                                    <td>
                                        <button
                                            onClick={
                                                e => {
                                                    setUser(user);
                                                    setForm("changepassword")
                                                }
                                            }
                                            className="btn btn-sm btn-primary fa fa-key"></button>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </Dashboard.Card>,
                "adduser": <Dashboard.Card title="Add User" buttons={ <Buttons onClick={ e => { setForm(null) } } className="btn btn-danger btn-sm fa fa-times" /> }>
                    <Form.Form
                        method="put"
                        action="/api/v1/user"
                        afterSubmit={e=>{
                            if(e.status==="success"){
                                setForm(null);
                            }
                            else{
                                alert(e.message)
                            }
                        }}
                    >
                        <Form.Input name="name" label="Name" />
                        <Form.Input name="user" label="Username" />
                        <Form.Input type="password" name="pass" label="Password" />
                        <Form.Input type="password" name="cnfpass" label="Confirm Password" />
                        <Form.Input type="email" name="email" label="E-Mail" />
                        <Form.Input type="tel" name="phone" label="Phone" />
                        <Form.Input name="role" label="Role" />
                    </Form.Form>
                </Dashboard.Card>,
                "updateuser": <Dashboard.Card title={ `Updating user ${user.user}` } buttons={ <Buttons onClick={ e => { setForm(null) } } className="btn btn-danger btn-sm fa fa-times" /> }>
                    <Form.Form
                    method="put"
                    action={`/api/v1/user/${user._id}`}
                    afterSubmit={e=>{
                        if(e.status==="success"){
                            setForm(null);
                        }
                        else{
                            alert(e.message)
                        }
                    }}
                    >
                         <Form.Input name="name" label="Name" defaultValue={user.name} />
                        <Form.Input name="user" label="Username" defaultValue={user.user} />
                        <Form.Input type="email" name="email" label="E-Mail" defaultValue={user.email} />
                        <Form.Input type="tel" name="phone" label="Phone" defaultValue={user.phone} />
                        <Form.Input name="role" label="Role" defaultValue={user.role} />
                    </Form.Form>
                </Dashboard.Card>,
                "deleteuser": <Dashboard.Card title={ `Deleting user ${user.user}` } buttons={ <Buttons onClick={ e => { setForm(null) } } className="btn btn-danger btn-sm fa fa-times" /> }>
                    Do you want to delete user { user.user } ({ user.email }). <button onClick={
                        async e=>{
                            let api=new API();
                            await api.delete(`/api/v1/user/${user._id}`);
                            setForm(null)
                        }
                    } className="btn btn-danger">Yes, Delete</button> <button className="btn btn-primary">Cancel</button>
                </Dashboard.Card>,
                "changepassword": <Dashboard.Card title={ `Set password for ${user.user}` } buttons={ <Buttons onClick={ e => { setForm(null) } } className="btn btn-danger btn-sm fa fa-times" /> }>
                    <Form.Form 
                    method="post"
                    action={`/api/v1/user/${user._id}`}
                    afterSubmit={e=>{
                        if(e.status==="success"){
                            setForm(null);
                        }
                        else{
                            alert(e.message)
                        }
                    }}>
                        <Form.Input name="pass" type="password" label="New Password" />
                        <Form.Input name="cnfpass" type="password" label="Confirm Password" />
                    </Form.Form>
                </Dashboard.Card>
            }[form]
        }

    </>
}