import React from "react";
import { Form } from "../../component";
export function Login() {
    return <div className="container f-al-center">
        <div className="form-container">
            <Form.Form
                method="post"
                action="/api/v1/login"
                title="Authentication"
            >
                <Form.Input label="Username" name="user" type="text" required />
                <Form.Input label="Password" name="pass" type="password" />
                <Form.Checkbox name="remember">
                    Remember me on this device
                </Form.Checkbox>
            </Form.Form>
        </div>
    </div>
    return <>Login Form</>
}
export function Resume() {
    return <>
        <div className="container f-al-center">
            <div className="form-container">
                <Form.Form
                    method="post"
                    action="/api/v1/resume"
                    enctype="multipart/form-data"
                    title="Career Form"
                >
                    <Form.Input
                        label="Name"
                        name="name"
                        pattern="^[a-zA-Z ]+$"
                        type="text"
                        required
                    />
                    <Form.Input
                        label="E-Mail"
                        name="email"
                        id="email"
                        required
                        type="email"
                    />
                    <Form.Input
                        label="Phone No."
                        name="phone"
                        id="phone"
                        type="tel"
                        required
                    />
                    <Form.File
                        name="resume"
                        accept="application/pdf"
                        text="Resume"
                    />
                    <Form.Checkbox name="concent">
                        I hearbe deleare that the information given in the resume are correct and I authorize Tech Talent solution to use this information. I also accept terms and condition of Tech Talent Solution.
                    </Form.Checkbox>
                </Form.Form>
            </div>
        </div>
    </>
}