function logout() {
  localStorage.clear();
  location.href = "/"
}
export default function (attr) {

  return <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark">
    <div className="sidenav-header">
          { attr.user.name }
    </div>
    <hr className="horizontal light mt-0 mb-2" />
    <ul className="navbar-nav">
      { attr.children }
      <li className="nav-item">
        <a className="cursor-pointer nav-link" onClick={ logout }>Logout</a>
      </li>
    </ul>
  </aside>
}