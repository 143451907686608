export default function Card(attr){
    return     <div className="card">
    <div className="card-header f-spbt">
      <div className="title">{attr.title}</div>
      <div className="buttons">
        {attr.buttons}
      </div>
    </div>
    <div className="card-body px-0 pb-2">
        {attr.children}
      
    </div>
  </div>

}