import { validateAndSubmit } from "../../service"
export default function (attr) {
    return <form method={ attr.method } action={ attr.action } encType={ attr.enctype }>
        <div className="form-group-title">
            <h2>{ attr.title }</h2>
            <img src="https://techtalentsolution.in/wp-content/themes/twentythirteen/images/img/logo.svg" />
        </div>
        { attr.children }
        <div className="form-group">
            <button type="button" onClick={e=>{validateAndSubmit(e,attr.afterSubmit)} } className="btn btn-primary" >Submit</button>
        </div>
    </form>
}