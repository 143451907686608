import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter} from "react-router-dom";
import RouteList from "./route/RouteList";

let container=document.querySelector("#app")
if(!container){
    container=document.createElement("div")
    container.setAttribute("id","app")
    document.body.appendChild(container)
}
let root=ReactDOM.createRoot(container);
root.render(<BrowserRouter>
<RouteList />
</BrowserRouter>)
