export default function (attr) {    
    return <div className="form-group">
        <label
            htmlFor={ attr.id ?? attr.name }
        >
            { attr.label }
        </label>
        <input
            type={ attr.type }
            id={ attr.id ?? attr.name }
            name={ attr.name }
            pattern={ attr.pattern }
            className="form-control"
            required={attr.required}
            defaultValue={attr.defaultValue}
        />
    </div>;
}