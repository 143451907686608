import React from "react"
import { useRoutes, Navigate } from "react-router-dom"
import API from "../service/API"
// import { API } from "../service";
import {
    Visitor as VisitorLayout,
    Admin as AdminLayout,
    User as UserLayout
} from "../layout";
import {
    Visitor as VisitorPage,
    Admin as AdminPage
} from "../page";
export default function () {
    const [user, setUser] = React.useState({});
    const [role, setRole] = React.useState(null);

    React.useEffect(() => {
        let token = localStorage.getItem("token");
        if (!token) return;
        // Token found... Verifying token
        const api = new API();
        api.get("/api/v1/verify")
            .then(resp => {
                // console.log(resp);
                switch (resp?.status) {
                    case "success":
                        if (resp?.data?.role) {
                            setRole(resp.data.role);
                            setUser(resp.data)
                        } else {
                            localStorage.clear();
                            location.href = "/";
                        }
                        break;
                    case "error":
                        localStorage.clear();
                        location.href = "/";
                        break;
                }
            })
            .catch(err => {
                console.log({ err })
                setRole(null);
            })
    }, [])
    console.log(role);
    // return <>Loaded</>
    switch (role) {
        case null:
            return useRoutes([
                {
                    path: "/",
                    element: <VisitorLayout />,
                    children: [
                        {
                            path: "/",
                            element: <VisitorPage.Resume />
                        },
                        {
                            path: "/login",
                            element: <VisitorPage.Login />
                        }
                    ]
                }
            ]);
        case "admin":
        case "root":
            return useRoutes([
                {
                    path: "/",
                    element: <AdminLayout user={ user } />,
                    children: [
                        {
                            path: "/",
                            element: <AdminPage.Dashboard />
                        },
                        {
                            path: "/applicants",
                            element: <AdminPage.Applicants />
                        },
                        {
                            path: "/users",
                            element: <AdminPage.Users />
                        },
                        {
                            path:"/applicants/:id",
                            element:<AdminPage.Applicants/>
                        }
                    ]
                }
            ])
        /* case "user":
            return useRoutes([
                { path: "/", element: <UserLayout /> }
            ])  */
        default:
            console.log("You are now working as " + role)
            return useRoutes([
                { path: "/", element: <VisitorLayout /> }
            ])
    }
}