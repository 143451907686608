import {API} from "./";

export function validateAndSubmit(e,afterSubmit=null) {
    

        let btn = e.target;
        if (!btn) return console.log("misconfigured");
        let frm = btn.form;
        if (!frm) return console.log("misconfigured");
        const valid = frm.checkValidity();
        if (!valid) return showErrors(frm);
        const action = frm.getAttribute("action");
        const method = frm.getAttribute("method");
        if (!action || !method) return console.log("misconfigured");
        // frm.classList.add("disabled");
        if (frm.hasFile()) {
            data = new FormData(frm);
            fetch(
                action,
                {
                    body: data,
                    method
                }
            )
                .then(r => r.json())
                .then(resp => {
                    let frmHeader = frm.querySelector(".form-group-title");
                    frm.innerHTML = `
                    ${frmHeader.innerHTML}
                    <div class="message">
                        <p>${resp.message}</p>
                    </div>
                    <div class="f-al-center">
                        <a href="/" class="btn btn-primary">Back</a>
                    </div>
                    `
                    frm.classList.remove("disabled")
                    // console.log(resp);
                })
                .catch(e => {
                    console.log(e);
                })
                return true;
        }
        const frmData=frm.getData();
        let api=new API();
        api[method](action,frmData)
        .then(info=>{
            if(!!afterSubmit){
                afterSubmit(info)
            }
            // console.log(info)
            if(!!info.token){
                localStorage.setItem("token",info.token);
                location.href="/"
            }
        })
}
export function showErrors(frm) {
    let invalidInputs = frm.getInvalidInputs();
    invalidInputs.forEach(inp => {
        let inpPDiv = inp.closest("div");
        if (inpPDiv.classList.contains("error")) return false;
        inpPDiv.classList.add("error");
        let label = inpPDiv.querySelector("label").innerText ?? inp.name;
        let errorSpan = document.createElement("span");
        errorSpan.innerText = (inp.validity.valueMissing) ? `` : `${label} is invalid`;
        inpPDiv.appendChild(errorSpan)
        inp.addEventListener("input", ev => {
            let span = inpPDiv.querySelector("span");
            (!!span) && span.remove();
            inpPDiv.classList.remove("error")
        })
    });
}
