import { Outlet, NavLink } from "react-router-dom";
// import "../../scss/material.scss"
import Sidebar from "../component/dashbaord/Sidebar";

export default function (info) {
  // console.log(info);
  return <div className="dashboard">
    <Sidebar { ...info }>
      <li className="nav-item">
        <NavLink className="nav-link" to="/applicants">
          Applicants
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link " to="/users">
          Users
        </NavLink>
      </li>
    </Sidebar>
    <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-12">
            <Outlet />
          </div>
        </div>
      </div>
    </main>
  </div>
}