import React from "react";
export default function (attr) {
    const [selectedFile, setSelectedFile] = React.useState(null);
    function uploadFile(e) {
        let btn = e.target;
        let parent = btn.parentElement;
        let fileInput = parent.querySelector("[type=file]");
        fileInput.click();
    }
    function fileUploaded(e) {
        setSelectedFile(e.target.files[0])
    }

    return <div className="form-group">
        <input
            onChange={ fileUploaded }
            type="file"
            name={attr.name}
            accept={attr.accept}
            className="form-control" />
        <span>{ selectedFile?.name }</span>
        <button type="button" className="btn btn-upload" onClick={ e => uploadFile(e) }>{ !selectedFile ? "Upload" : "Change" } {attr.text}</button>
    </div>
}