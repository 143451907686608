export default class API {
    #headers = {
        "Content-Type": "application/json",
    };
    #endpoint = "";
    constructor(noauth=false) { 
        if(noauth){
            return;
        }
        let token=localStorage.getItem("token");
        if(!!token){
            this.header={
                Authorization: `Bearer ${token}`
            }
        }
    }
    async execute(url, method = "GET", data = null) {
        let options = {
            method: method,
            headers: this.#headers,
        };
        if (data) options.body = JSON.stringify(data);
        const response = await fetch(this.#endpoint + url, options);
        let resp=await response.json();
        if(resp.status==="error" && resp.message==="Token Expired"){
            localStorage.clear();
            location.href="/";
        }
        console.log(resp);
        return resp;
    }
    async get(url) {
        return this.execute(url);
    }
    async post(url, data) {
        return this.execute(url, "POST", data);
    }
    async put(url, data) {
        return this.execute(url, "PUT", data);
    }
    async patch(url, data) {
        return this.execute(url, "PATCH", data);
    }
    async delete(url, data) {
        return this.execute(url, "DELETE", data);
    }
    async copy(url, data) {
        return this.execute(url, "COPY", data);
    }
    async getHTML(url) {
        let response = await fetch(url);
        return response.text();
    }
    get headers() {
        return this.#headers;
    }
    set headers(headers) {
        this.#headers = headers;
    }
    get endpoint() {
        return this.#endpoint;
    }
    set endpoint(endpoint) {
        this.#endpoint = endpoint;
    }
    set header(object) {
        Object.assign(this.#headers, object);
    }
}
