import API from "../../service/API"
import {Dashboard }from "../../component";
const Card=Dashboard.Card;
import React from "react";
import { formatDateTime } from "../../service";

export default function(attr){
    const [applicants,setApplicants]=React.useState([]);
    React.useEffect(()=>{
        const api=new API();
        api.get("/api/v1/resume").then(resp=>{
            if("data" in resp){
                setApplicants(resp.data);
            }
        })
    },[])
    return <>
    <Card title="Applicants">
    <div className="table-responsive p-0">
        <table className="table align-items-center mb-0">
          <thead>
            <tr>
                <th>Sl.No.</th>
                <th>Application Date</th>
                <th>Name</th>
                <th>E Mail</th>
                <th>Phone No.</th>
                <th></th>
            </tr>
          </thead>
          <tbody>
            {
                applicants.map((applicant,index)=><tr key={applicant._id}>
                    <td>{(index + 1)}</td>
                    <td>{`${formatDateTime(new Date(applicant.createdAt))}`}</td>
                    <td>{applicant.name}</td>
                    <td>{applicant.email}</td>
                    <td>{applicant.phone}</td>
                    <td>
                        <a className="btn btn-primary" href={`/applicants/${applicant._id}`} target="_blank">Resume</a>
                    </td>
                </tr>)
            }
          </tbody>
        </table>
      </div>

    </Card>
    </>
}