import API from "../../service/API";
import { Dashboard } from "./";
import { Form, Dashboard as PageComponents } from "../../component";
const Card = PageComponents.Card;
import React from "react";
import { useParams } from "react-router-dom";

export default function (attr) {
    const [applicant, setApplicant] = React.useState({});
    const params = useParams();
    if (!params.id)
        return <Dashboard />
    async function deleteKeyword(keyword) {
        const api = new API();
        let resp = await api.delete(`/api/v1/resume/${params.id}`, { keyword })
        if (resp.status === "success") {
            setApplicant(resp.info)
        }
    }
    React.useEffect(() => {
        const api = new API();
        api.get(`/api/v1/resume/${params.id}`).then(
            resp => {
                setApplicant(resp.info);
            }
        )
    }, []);

    return <>
        <div className="userI">
            <div className="left">
                <Form.Form
                    method="post"
                    action={ `/api/v1/resume/${params.id}` }
                    afterSubmit={ e => {
                        console.log(e);
                        if (e.status == "success") {
                            setApplicant(e.info)
                        }
                    } }
                >
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Input
                                        name="name"
                                        type="text"
                                        label="Name"
                                        defaultValue={ applicant.name }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><Form.Input
                                    type="email"
                                    name="email"
                                    label="E-Mail"
                                    defaultValue={ applicant.email }
                                /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Input
                                        name="phone"
                                        type="tel"
                                        defaultValue={ applicant.phone }
                                        label="Phone"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Input
                                        label="Add Keywords"
                                        name="keywords"
                                        type="text"

                                    />
                                    <ul className="keywords">
                                        {
                                            ("keywords" in applicant) && (applicant.keywords instanceof Array) && applicant.keywords.map(keyword => <li className="keyword" key={ keyword }>{ keyword }<button type="button" onClick={ e => { deleteKeyword(keyword) } }>&times;</button></li>)
                                        }
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form.Form>
            </div>
            <div className="right">
                <iframe src={ `/applicants/${params.id}/resume.pdf` }></iframe>
            </div>
        </div>
    </>
    // React.useEffect(()=>{
    //     const api=new API();
    //     api.get("/api/v1/resume").then(resp=>{
    //         if("data" in resp){
    //             setApplicants(resp.data);
    //         }
    //     })
    // },[])
    // return <>
    // <Card title="Applicants">
    // <div className="table-responsive p-0">
    //     <table className="table align-items-center mb-0">
    //       <thead>
    //         <tr>
    //             <th>Sl.No.</th>
    //             <th>Application Date</th>
    //             <th>Name</th>
    //             <th>E Mail</th>
    //             <th>Phone No.</th>
    //             <th></th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         {
    //             applicants.map((applicant,index)=><tr>
    //                 <td>{(index + 1)}</td>
    //                 <td>{applicant.createdAt}</td>
    //                 <td>{applicant.name}</td>
    //                 <td>{applicant.email}</td>
    //                 <td>{applicant.phone}</td>
    //                 <td>
    //                     <a className="btn btn-primary" href={`/applicants/${applicant._id}/resume.pdf`} target="_blank">Resume</a>
    //                 </td>
    //             </tr>)
    //         }
    //       </tbody>
    //     </table>
    //   </div>

    // </Card>
    // </>
}